/**
 * @generated SignedSource<<1a51c32667191770a6fc4f3b0bc9aa60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OtaOperationStatus = "ACKNOWLEDGED" | "DEPLOYED" | "DEPLOYING" | "DOWNLOADING" | "ERROR" | "FAILURE" | "PENDING" | "REBOOTING" | "SUCCESS";
export type OtaOperationStatusCode = "CANCELED" | "INTERNAL_ERROR" | "INVALID_BASE_IMAGE" | "INVALID_REQUEST" | "IO_ERROR" | "NETWORK_ERROR" | "REQUEST_TIMEOUT" | "SYSTEM_ROLLBACK" | "UPDATE_ALREADY_IN_PROGRESS";
import { FragmentRefs } from "relay-runtime";
export type UpdateTargetsTable_UpdateTargetsFragment$data = ReadonlyArray<{
  readonly completionTimestamp: string | null;
  readonly device: {
    readonly id: string;
    readonly name: string;
  };
  readonly latestAttempt: string | null;
  readonly otaOperation: {
    readonly status: OtaOperationStatus;
    readonly statusCode: OtaOperationStatusCode | null;
    readonly statusProgress: number;
  } | null;
  readonly " $fragmentType": "UpdateTargetsTable_UpdateTargetsFragment";
}>;
export type UpdateTargetsTable_UpdateTargetsFragment$key = ReadonlyArray<{
  readonly " $data"?: UpdateTargetsTable_UpdateTargetsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateTargetsTable_UpdateTargetsFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UpdateTargetsTable_UpdateTargetsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestAttempt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completionTimestamp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OtaOperation",
      "kind": "LinkedField",
      "name": "otaOperation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "statusProgress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "statusCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UpdateTarget",
  "abstractKey": null
};

(node as any).hash = "aeaa5f52595fd607b656f622af733982";

export default node;
