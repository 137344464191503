/**
 * @generated SignedSource<<8879afbacc2a89e787354019c69f8d64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateCampaign_getUpdateCampaign_Query$variables = {
  updateCampaignId: string;
};
export type UpdateCampaign_getUpdateCampaign_Query$data = {
  readonly updateCampaign: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"UpdateCampaignForm_UpdateCampaignFragment" | "UpdateCampaignStatsChart_UpdateCampaignStatsChartFragment" | "UpdateCampaign_RefreshFragment" | "UpdateTargetsTabs_UpdateTargetsFragment">;
  } | null;
};
export type UpdateCampaign_getUpdateCampaign_Query = {
  response: UpdateCampaign_getUpdateCampaign_Query$data;
  variables: UpdateCampaign_getUpdateCampaign_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updateCampaignId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "updateCampaignId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCampaign_getUpdateCampaign_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCampaign",
        "kind": "LinkedField",
        "name": "updateCampaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateCampaignForm_UpdateCampaignFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateCampaignStatsChart_UpdateCampaignStatsChartFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateTargetsTabs_UpdateTargetsFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateCampaign_RefreshFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCampaign_getUpdateCampaign_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCampaign",
        "kind": "LinkedField",
        "name": "updateCampaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "outcome",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BaseImage",
            "kind": "LinkedField",
            "name": "baseImage",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "releaseDisplayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BaseImageCollection",
                "kind": "LinkedField",
                "name": "baseImageCollection",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UpdateChannel",
            "kind": "LinkedField",
            "name": "updateChannel",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "rolloutMechanism",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxFailurePercentage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxInProgressUpdates",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "otaRequestRetries",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "otaRequestTimeoutSeconds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "forceDowngrade",
                    "storageKey": null
                  }
                ],
                "type": "PushRollout",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UpdateCampaignStats",
            "kind": "LinkedField",
            "name": "stats",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "idleTargetCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inProgressTargetCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "failedTargetCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "successfulTargetCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UpdateTarget",
            "kind": "LinkedField",
            "name": "updateTargets",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "latestAttempt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completionTimestamp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OtaOperation",
                "kind": "LinkedField",
                "name": "otaOperation",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "statusProgress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "statusCode",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a176c4073845a3a52b2d0fae7b69042e",
    "id": null,
    "metadata": {},
    "name": "UpdateCampaign_getUpdateCampaign_Query",
    "operationKind": "query",
    "text": "query UpdateCampaign_getUpdateCampaign_Query(\n  $updateCampaignId: ID!\n) {\n  updateCampaign(id: $updateCampaignId) {\n    name\n    ...UpdateCampaignForm_UpdateCampaignFragment\n    ...UpdateCampaignStatsChart_UpdateCampaignStatsChartFragment\n    ...UpdateTargetsTabs_UpdateTargetsFragment\n    ...UpdateCampaign_RefreshFragment\n    id\n  }\n}\n\nfragment BaseImageNameResolver on BaseImage {\n  version\n  releaseDisplayName\n}\n\nfragment UpdateCampaignForm_UpdateCampaignFragment on UpdateCampaign {\n  ...UpdateCampaignStatus_UpdateCampaignStatusFragment\n  ...UpdateCampaignOutcome_UpdateCampaignOutcomeFragment\n  baseImage {\n    id\n    ...BaseImageNameResolver\n    baseImageCollection {\n      id\n      name\n    }\n  }\n  updateChannel {\n    id\n    name\n  }\n  rolloutMechanism {\n    __typename\n    ... on PushRollout {\n      maxFailurePercentage\n      maxInProgressUpdates\n      otaRequestRetries\n      otaRequestTimeoutSeconds\n      forceDowngrade\n    }\n  }\n}\n\nfragment UpdateCampaignOutcome_UpdateCampaignOutcomeFragment on UpdateCampaign {\n  outcome\n}\n\nfragment UpdateCampaignStatsChart_UpdateCampaignStatsChartFragment on UpdateCampaign {\n  stats {\n    idleTargetCount\n    inProgressTargetCount\n    failedTargetCount\n    successfulTargetCount\n  }\n}\n\nfragment UpdateCampaignStatus_UpdateCampaignStatusFragment on UpdateCampaign {\n  status\n}\n\nfragment UpdateCampaign_RefreshFragment on UpdateCampaign {\n  id\n  status\n}\n\nfragment UpdateTargetsTable_UpdateTargetsFragment on UpdateTarget {\n  device {\n    id\n    name\n  }\n  latestAttempt\n  completionTimestamp\n  otaOperation {\n    status\n    statusProgress\n    statusCode\n    id\n  }\n}\n\nfragment UpdateTargetsTabs_UpdateTargetsFragment on UpdateCampaign {\n  updateTargets {\n    status\n    ...UpdateTargetsTable_UpdateTargetsFragment\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "08f838a2e44c4f4739e2f50aa308ffd9";

export default node;
