/**
 * @generated SignedSource<<1f6bdea8c0a0f562b8f63773b9ac86f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateUpdateCampaignInput = {
  baseImageId: string;
  name: string;
  rolloutMechanism: RolloutMechanismInput;
  updateChannelId: string;
};
export type RolloutMechanismInput = {
  push: PushRolloutInput;
};
export type PushRolloutInput = {
  forceDowngrade?: boolean | null;
  maxFailurePercentage: number;
  maxInProgressUpdates: number;
  otaRequestRetries?: number | null;
  otaRequestTimeoutSeconds?: number | null;
};
export type UpdateCampaignCreate_createUpdateCampaign_Mutation$variables = {
  input: CreateUpdateCampaignInput;
};
export type UpdateCampaignCreate_createUpdateCampaign_Mutation$data = {
  readonly createUpdateCampaign: {
    readonly updateCampaign: {
      readonly id: string;
    };
  } | null;
};
export type UpdateCampaignCreate_createUpdateCampaign_Mutation = {
  response: UpdateCampaignCreate_createUpdateCampaign_Mutation$data;
  variables: UpdateCampaignCreate_createUpdateCampaign_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateUpdateCampaignPayload",
    "kind": "LinkedField",
    "name": "createUpdateCampaign",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateCampaign",
        "kind": "LinkedField",
        "name": "updateCampaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCampaignCreate_createUpdateCampaign_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCampaignCreate_createUpdateCampaign_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0165f8dc67029f335c67c77d38b99b5e",
    "id": null,
    "metadata": {},
    "name": "UpdateCampaignCreate_createUpdateCampaign_Mutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCampaignCreate_createUpdateCampaign_Mutation(\n  $input: CreateUpdateCampaignInput!\n) {\n  createUpdateCampaign(input: $input) {\n    updateCampaign {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eaa3f09981a7b32316c5a82a499118b5";

export default node;
