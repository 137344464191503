/**
 * @generated SignedSource<<8620ac843efb206b24ebcb5e59b06d45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UpdateChannelCreate_getDeviceGroups_Query$variables = {};
export type UpdateChannelCreate_getDeviceGroups_Query$data = {
  readonly deviceGroups: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly updateChannel: {
      readonly name: string;
    } | null;
  }>;
};
export type UpdateChannelCreate_getDeviceGroups_Query = {
  response: UpdateChannelCreate_getDeviceGroups_Query$data;
  variables: UpdateChannelCreate_getDeviceGroups_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateChannelCreate_getDeviceGroups_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceGroup",
        "kind": "LinkedField",
        "name": "deviceGroups",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UpdateChannel",
            "kind": "LinkedField",
            "name": "updateChannel",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UpdateChannelCreate_getDeviceGroups_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceGroup",
        "kind": "LinkedField",
        "name": "deviceGroups",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UpdateChannel",
            "kind": "LinkedField",
            "name": "updateChannel",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b691da08032db125c0e8ca7db55f0a47",
    "id": null,
    "metadata": {},
    "name": "UpdateChannelCreate_getDeviceGroups_Query",
    "operationKind": "query",
    "text": "query UpdateChannelCreate_getDeviceGroups_Query {\n  deviceGroups {\n    id\n    name\n    updateChannel {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6b74157ed7dae73520ea1e880d583f38";

export default node;
