/**
 * @generated SignedSource<<bb55536b6d5d6fa598fa402cbb3728f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceGroup_getDeviceGroup_Query$variables = {
  id: string;
};
export type DeviceGroup_getDeviceGroup_Query$data = {
  readonly deviceGroup: {
    readonly devices: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"DevicesTable_DeviceFragment">;
    }>;
    readonly handle: string;
    readonly id: string;
    readonly name: string;
    readonly selector: string;
  } | null;
};
export type DeviceGroup_getDeviceGroup_Query = {
  response: DeviceGroup_getDeviceGroup_Query$data;
  variables: DeviceGroup_getDeviceGroup_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "selector",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceGroup_getDeviceGroup_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeviceGroup",
        "kind": "LinkedField",
        "name": "deviceGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "devices",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DevicesTable_DeviceFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviceGroup_getDeviceGroup_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeviceGroup",
        "kind": "LinkedField",
        "name": "deviceGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "devices",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deviceId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastConnection",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastDisconnection",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "online",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SystemModel",
                "kind": "LinkedField",
                "name": "systemModel",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HardwareType",
                    "kind": "LinkedField",
                    "name": "hardwareType",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tags",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b0bba8455a47c96f6c3328d86aa3e1a4",
    "id": null,
    "metadata": {},
    "name": "DeviceGroup_getDeviceGroup_Query",
    "operationKind": "query",
    "text": "query DeviceGroup_getDeviceGroup_Query(\n  $id: ID!\n) {\n  deviceGroup(id: $id) {\n    id\n    name\n    handle\n    selector\n    devices {\n      ...DevicesTable_DeviceFragment\n      id\n    }\n  }\n}\n\nfragment DevicesTable_DeviceFragment on Device {\n  id\n  deviceId\n  lastConnection\n  lastDisconnection\n  name\n  online\n  systemModel {\n    name\n    hardwareType {\n      name\n      id\n    }\n    id\n  }\n  tags\n}\n"
  }
};
})();

(node as any).hash = "91c5124f8118d9a66ba0a3dba6c67b2e";

export default node;
