/**
 * @generated SignedSource<<7b236040bae8754d1edea49ad0a4c18f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WiFiScanResultsTable_wifiScanResults$data = {
  readonly wifiScanResults: ReadonlyArray<{
    readonly channel: number | null;
    readonly connected: boolean | null;
    readonly essid: string | null;
    readonly macAddress: string | null;
    readonly rssi: number | null;
    readonly timestamp: string;
  }> | null;
  readonly " $fragmentType": "WiFiScanResultsTable_wifiScanResults";
};
export type WiFiScanResultsTable_wifiScanResults$key = {
  readonly " $data"?: WiFiScanResultsTable_wifiScanResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"WiFiScanResultsTable_wifiScanResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WiFiScanResultsTable_wifiScanResults",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WifiScanResult",
      "kind": "LinkedField",
      "name": "wifiScanResults",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "channel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "connected",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "essid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "macAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rssi",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timestamp",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "729523f2d0ee74e339e993f6c71b2dc3";

export default node;
