/**
 * @generated SignedSource<<084228b9b550b908da84c3d7bb0fe180>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateUpdateChannelInput = {
  handle: string;
  name: string;
  targetGroupIds: ReadonlyArray<string>;
};
export type UpdateChannelCreate_createUpdateChannel_Mutation$variables = {
  input: CreateUpdateChannelInput;
};
export type UpdateChannelCreate_createUpdateChannel_Mutation$data = {
  readonly createUpdateChannel: {
    readonly updateChannel: {
      readonly handle: string;
      readonly id: string;
      readonly name: string;
      readonly targetGroups: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
    };
  } | null;
};
export type UpdateChannelCreate_createUpdateChannel_Mutation = {
  response: UpdateChannelCreate_createUpdateChannel_Mutation$data;
  variables: UpdateChannelCreate_createUpdateChannel_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateUpdateChannelPayload",
    "kind": "LinkedField",
    "name": "createUpdateChannel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateChannel",
        "kind": "LinkedField",
        "name": "updateChannel",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceGroup",
            "kind": "LinkedField",
            "name": "targetGroups",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateChannelCreate_createUpdateChannel_Mutation",
    "selections": (v3/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateChannelCreate_createUpdateChannel_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "aea99da250ccc45261e16eed47661bf2",
    "id": null,
    "metadata": {},
    "name": "UpdateChannelCreate_createUpdateChannel_Mutation",
    "operationKind": "mutation",
    "text": "mutation UpdateChannelCreate_createUpdateChannel_Mutation(\n  $input: CreateUpdateChannelInput!\n) {\n  createUpdateChannel(input: $input) {\n    updateChannel {\n      id\n      name\n      handle\n      targetGroups {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f9f90b6d55af6e7e25fe02af3b7a75c0";

export default node;
