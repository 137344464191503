/**
 * @generated SignedSource<<968a6d27fd87399b9ba80b426e6e9c5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UpdateCampaignStatus = "FINISHED" | "IDLE" | "IN_PROGRESS";
import { FragmentRefs } from "relay-runtime";
export type UpdateCampaignStatus_UpdateCampaignStatusFragment$data = {
  readonly status: UpdateCampaignStatus;
  readonly " $fragmentType": "UpdateCampaignStatus_UpdateCampaignStatusFragment";
};
export type UpdateCampaignStatus_UpdateCampaignStatusFragment$key = {
  readonly " $data"?: UpdateCampaignStatus_UpdateCampaignStatusFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateCampaignStatus_UpdateCampaignStatusFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateCampaignStatus_UpdateCampaignStatusFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "UpdateCampaign",
  "abstractKey": null
};

(node as any).hash = "32d6f090accd028d0beace56d1ba9a8e";

export default node;
