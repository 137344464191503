/**
 * @generated SignedSource<<21ebc91a42f1c08f9c00fbaf1d5c7d98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BaseImage_getBaseImage_Query$variables = {
  id: string;
};
export type BaseImage_getBaseImage_Query$data = {
  readonly baseImage: {
    readonly baseImageCollection: {
      readonly id: string;
      readonly name: string;
    };
    readonly description: string | null;
    readonly id: string;
    readonly releaseDisplayName: string | null;
    readonly startingVersionRequirement: string | null;
    readonly url: string;
    readonly version: string;
  } | null;
  readonly tenantInfo: {
    readonly defaultLocale: string;
  };
};
export type BaseImage_getBaseImage_Query = {
  response: BaseImage_getBaseImage_Query$data;
  variables: BaseImage_getBaseImage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "BaseImage",
    "kind": "LinkedField",
    "name": "baseImage",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startingVersionRequirement",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "releaseDisplayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BaseImageCollection",
        "kind": "LinkedField",
        "name": "baseImageCollection",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TenantInfo",
    "kind": "LinkedField",
    "name": "tenantInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultLocale",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BaseImage_getBaseImage_Query",
    "selections": (v2/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BaseImage_getBaseImage_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5ab73b4806c79953cef0a4f79ef175ab",
    "id": null,
    "metadata": {},
    "name": "BaseImage_getBaseImage_Query",
    "operationKind": "query",
    "text": "query BaseImage_getBaseImage_Query(\n  $id: ID!\n) {\n  baseImage(id: $id) {\n    id\n    version\n    url\n    startingVersionRequirement\n    releaseDisplayName\n    description\n    baseImageCollection {\n      id\n      name\n    }\n  }\n  tenantInfo {\n    defaultLocale\n  }\n}\n"
  }
};
})();

(node as any).hash = "ca4af93cb238b6be7b5df3fc7f815d3b";

export default node;
