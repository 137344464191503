/**
 * @generated SignedSource<<f4c2dfb9df16c578cf654927c9d3a31a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OtaOperationStatus = "ACKNOWLEDGED" | "DEPLOYED" | "DEPLOYING" | "DOWNLOADING" | "ERROR" | "FAILURE" | "PENDING" | "REBOOTING" | "SUCCESS";
export type OtaOperationStatusCode = "CANCELED" | "INTERNAL_ERROR" | "INVALID_BASE_IMAGE" | "INVALID_REQUEST" | "IO_ERROR" | "NETWORK_ERROR" | "REQUEST_TIMEOUT" | "SYSTEM_ROLLBACK" | "UPDATE_ALREADY_IN_PROGRESS";
export type CreateManualOtaOperationInput = {
  baseImageFile?: File | null;
  deviceId: string;
};
export type Device_createManualOtaOperation_Mutation$variables = {
  input: CreateManualOtaOperationInput;
};
export type Device_createManualOtaOperation_Mutation$data = {
  readonly createManualOtaOperation: {
    readonly otaOperation: {
      readonly baseImageUrl: string;
      readonly createdAt: string;
      readonly id: string;
      readonly status: OtaOperationStatus;
      readonly statusCode: OtaOperationStatusCode | null;
      readonly updatedAt: string;
    };
  } | null;
};
export type Device_createManualOtaOperation_Mutation = {
  response: Device_createManualOtaOperation_Mutation$data;
  variables: Device_createManualOtaOperation_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateManualOtaOperationPayload",
    "kind": "LinkedField",
    "name": "createManualOtaOperation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OtaOperation",
        "kind": "LinkedField",
        "name": "otaOperation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "baseImageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "statusCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Device_createManualOtaOperation_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Device_createManualOtaOperation_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "08dbacce82b15631a95d22a63ac74442",
    "id": null,
    "metadata": {},
    "name": "Device_createManualOtaOperation_Mutation",
    "operationKind": "mutation",
    "text": "mutation Device_createManualOtaOperation_Mutation(\n  $input: CreateManualOtaOperationInput!\n) {\n  createManualOtaOperation(input: $input) {\n    otaOperation {\n      id\n      baseImageUrl\n      createdAt\n      status\n      statusCode\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe865fe5aac4b7a1f45a5e91229586b8";

export default node;
