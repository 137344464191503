/**
 * @generated SignedSource<<3a4ebfcfad372136e0b9d2f5d5beaf41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NetworkInterfaceTechnology = "BLUETOOTH" | "CELLULAR" | "ETHERNET" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type NetworkInterfacesTable_networkInterfaces$data = {
  readonly networkInterfaces: ReadonlyArray<{
    readonly macAddress: string | null;
    readonly name: string;
    readonly technology: NetworkInterfaceTechnology | null;
  }> | null;
  readonly " $fragmentType": "NetworkInterfacesTable_networkInterfaces";
};
export type NetworkInterfacesTable_networkInterfaces$key = {
  readonly " $data"?: NetworkInterfacesTable_networkInterfaces$data;
  readonly " $fragmentSpreads": FragmentRefs<"NetworkInterfacesTable_networkInterfaces">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NetworkInterfacesTable_networkInterfaces",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NetworkInterface",
      "kind": "LinkedField",
      "name": "networkInterfaces",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "macAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "technology",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "52614172048ca3e5ebbf77aaca1e8634";

export default node;
