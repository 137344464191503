/**
 * @generated SignedSource<<935f5b2f42fc6e86ace84dc9461bbd3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateDeviceGroupInput = {
  deviceGroupId: string;
  handle?: string | null;
  name?: string | null;
  selector?: string | null;
};
export type DeviceGroup_updateDeviceGroup_Mutation$variables = {
  input: UpdateDeviceGroupInput;
};
export type DeviceGroup_updateDeviceGroup_Mutation$data = {
  readonly updateDeviceGroup: {
    readonly deviceGroup: {
      readonly devices: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"DevicesTable_DeviceFragment">;
      }>;
      readonly handle: string;
      readonly id: string;
      readonly name: string;
      readonly selector: string;
    };
  } | null;
};
export type DeviceGroup_updateDeviceGroup_Mutation = {
  response: DeviceGroup_updateDeviceGroup_Mutation$data;
  variables: DeviceGroup_updateDeviceGroup_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "selector",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceGroup_updateDeviceGroup_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDeviceGroupPayload",
        "kind": "LinkedField",
        "name": "updateDeviceGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceGroup",
            "kind": "LinkedField",
            "name": "deviceGroup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "devices",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DevicesTable_DeviceFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviceGroup_updateDeviceGroup_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDeviceGroupPayload",
        "kind": "LinkedField",
        "name": "updateDeviceGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceGroup",
            "kind": "LinkedField",
            "name": "deviceGroup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "devices",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deviceId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastConnection",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastDisconnection",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "online",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SystemModel",
                    "kind": "LinkedField",
                    "name": "systemModel",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HardwareType",
                        "kind": "LinkedField",
                        "name": "hardwareType",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tags",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c3aaf903040a3bede69bf12da0816c58",
    "id": null,
    "metadata": {},
    "name": "DeviceGroup_updateDeviceGroup_Mutation",
    "operationKind": "mutation",
    "text": "mutation DeviceGroup_updateDeviceGroup_Mutation(\n  $input: UpdateDeviceGroupInput!\n) {\n  updateDeviceGroup(input: $input) {\n    deviceGroup {\n      id\n      name\n      handle\n      selector\n      devices {\n        ...DevicesTable_DeviceFragment\n        id\n      }\n    }\n  }\n}\n\nfragment DevicesTable_DeviceFragment on Device {\n  id\n  deviceId\n  lastConnection\n  lastDisconnection\n  name\n  online\n  systemModel {\n    name\n    hardwareType {\n      name\n      id\n    }\n    id\n  }\n  tags\n}\n"
  }
};
})();

(node as any).hash = "c4043bee91cf570a13fbd37ff8ca88da";

export default node;
