/**
 * @generated SignedSource<<0580fa56ff1fa05ef0987bf65dee839c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import baseImageNameResolver from "../BaseImageNameResolver.ts";
export type UpdateCampaignForm_UpdateCampaignFragment$data = {
  readonly baseImage: {
    readonly baseImageCollection: {
      readonly id: string;
      readonly name: string;
    };
    readonly id: string;
    readonly name: ReturnType<typeof baseImageNameResolver> | null;
  };
  readonly rolloutMechanism: {
    readonly __typename: "PushRollout";
    readonly forceDowngrade: boolean;
    readonly maxFailurePercentage: number;
    readonly maxInProgressUpdates: number;
    readonly otaRequestRetries: number;
    readonly otaRequestTimeoutSeconds: number;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly updateChannel: {
    readonly id: string;
    readonly name: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"UpdateCampaignOutcome_UpdateCampaignOutcomeFragment" | "UpdateCampaignStatus_UpdateCampaignStatusFragment">;
  readonly " $fragmentType": "UpdateCampaignForm_UpdateCampaignFragment";
};
export type UpdateCampaignForm_UpdateCampaignFragment$key = {
  readonly " $data"?: UpdateCampaignForm_UpdateCampaignFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateCampaignForm_UpdateCampaignFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateCampaignForm_UpdateCampaignFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateCampaignStatus_UpdateCampaignStatusFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateCampaignOutcome_UpdateCampaignOutcomeFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseImage",
      "kind": "LinkedField",
      "name": "baseImage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BaseImageNameResolver"
          },
          "kind": "RelayResolver",
          "name": "name",
          "resolverModule": require('./../BaseImageNameResolver'),
          "path": "baseImage.name"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BaseImageCollection",
          "kind": "LinkedField",
          "name": "baseImageCollection",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UpdateChannel",
      "kind": "LinkedField",
      "name": "updateChannel",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "rolloutMechanism",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxFailurePercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxInProgressUpdates",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "otaRequestRetries",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "otaRequestTimeoutSeconds",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forceDowngrade",
              "storageKey": null
            }
          ],
          "type": "PushRollout",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UpdateCampaign",
  "abstractKey": null
};
})();

(node as any).hash = "4e43f2019bb8a47f757febf370218f96";

export default node;
