/**
 * @generated SignedSource<<440d2369ee54aaa980736d564f00dcd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateChannels_getUpdateChannels_Query$variables = {};
export type UpdateChannels_getUpdateChannels_Query$data = {
  readonly updateChannels: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UpdateChannelsTable_UpdateChannelFragment">;
  }>;
};
export type UpdateChannels_getUpdateChannels_Query = {
  response: UpdateChannels_getUpdateChannels_Query$data;
  variables: UpdateChannels_getUpdateChannels_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateChannels_getUpdateChannels_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateChannel",
        "kind": "LinkedField",
        "name": "updateChannels",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateChannelsTable_UpdateChannelFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UpdateChannels_getUpdateChannels_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateChannel",
        "kind": "LinkedField",
        "name": "updateChannels",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceGroup",
            "kind": "LinkedField",
            "name": "targetGroups",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5e62e0a55648633de5e103d9d0d9e1d0",
    "id": null,
    "metadata": {},
    "name": "UpdateChannels_getUpdateChannels_Query",
    "operationKind": "query",
    "text": "query UpdateChannels_getUpdateChannels_Query {\n  updateChannels {\n    ...UpdateChannelsTable_UpdateChannelFragment\n    id\n  }\n}\n\nfragment UpdateChannelsTable_UpdateChannelFragment on UpdateChannel {\n  id\n  name\n  handle\n  targetGroups {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b9b651ce214c948e8fa473db95099766";

export default node;
