/**
 * @generated SignedSource<<a73c7625016ee6580370b4b24d1c8bfa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BaseImagesTable_BaseImagesFragment$data = {
  readonly baseImages: ReadonlyArray<{
    readonly id: string;
    readonly releaseDisplayName: string | null;
    readonly startingVersionRequirement: string | null;
    readonly version: string;
  }>;
  readonly id: string;
  readonly " $fragmentType": "BaseImagesTable_BaseImagesFragment";
};
export type BaseImagesTable_BaseImagesFragment$key = {
  readonly " $data"?: BaseImagesTable_BaseImagesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BaseImagesTable_BaseImagesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BaseImagesTable_BaseImagesFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseImage",
      "kind": "LinkedField",
      "name": "baseImages",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "version",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startingVersionRequirement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "releaseDisplayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BaseImageCollection",
  "abstractKey": null
};
})();

(node as any).hash = "d5e01b8c4d4a6be6975d2f7bd6c8da49";

export default node;
