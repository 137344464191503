/**
 * @generated SignedSource<<b08e7270a9ebe46502559c764221daaa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceCapability = "BASE_IMAGE" | "BATTERY_STATUS" | "CELLULAR_CONNECTION" | "COMMANDS" | "GEOLOCATION" | "HARDWARE_INFO" | "LED_BEHAVIORS" | "NETWORK_INTERFACE_INFO" | "OPERATING_SYSTEM" | "RUNTIME_INFO" | "SOFTWARE_UPDATES" | "STORAGE" | "SYSTEM_INFO" | "SYSTEM_STATUS" | "TELEMETRY_CONFIG" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type Device_wifiScanResults$data = {
  readonly capabilities: ReadonlyArray<DeviceCapability>;
  readonly " $fragmentSpreads": FragmentRefs<"WiFiScanResultsTable_wifiScanResults">;
  readonly " $fragmentType": "Device_wifiScanResults";
};
export type Device_wifiScanResults$key = {
  readonly " $data"?: Device_wifiScanResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"Device_wifiScanResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Device_wifiScanResults",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capabilities",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WiFiScanResultsTable_wifiScanResults"
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "badabb1812a809a24e955f6511cbe6c1";

export default node;
