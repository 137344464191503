/**
 * @generated SignedSource<<296e8d6c07b5658584e209b742deb367>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateChannelsTable_UpdateChannelFragment$data = ReadonlyArray<{
  readonly handle: string;
  readonly id: string;
  readonly name: string;
  readonly targetGroups: ReadonlyArray<{
    readonly name: string;
  }>;
  readonly " $fragmentType": "UpdateChannelsTable_UpdateChannelFragment";
}>;
export type UpdateChannelsTable_UpdateChannelFragment$key = ReadonlyArray<{
  readonly " $data"?: UpdateChannelsTable_UpdateChannelFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateChannelsTable_UpdateChannelFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UpdateChannelsTable_UpdateChannelFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceGroup",
      "kind": "LinkedField",
      "name": "targetGroups",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "UpdateChannel",
  "abstractKey": null
};
})();

(node as any).hash = "ddff080e9a277209570a98adb3787e1b";

export default node;
