/**
 * @generated SignedSource<<387eea685c312faffeba6c2a0625c861>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateDeviceGroupInput = {
  handle: string;
  name: string;
  selector: string;
};
export type DeviceGroupCreate_createDeviceGroup_Mutation$variables = {
  input: CreateDeviceGroupInput;
};
export type DeviceGroupCreate_createDeviceGroup_Mutation$data = {
  readonly createDeviceGroup: {
    readonly deviceGroup: {
      readonly devices: ReadonlyArray<{
        readonly id: string;
      }>;
      readonly handle: string;
      readonly id: string;
      readonly name: string;
      readonly selector: string;
    };
  } | null;
};
export type DeviceGroupCreate_createDeviceGroup_Mutation = {
  response: DeviceGroupCreate_createDeviceGroup_Mutation$data;
  variables: DeviceGroupCreate_createDeviceGroup_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDeviceGroupPayload",
    "kind": "LinkedField",
    "name": "createDeviceGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceGroup",
        "kind": "LinkedField",
        "name": "deviceGroup",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "selector",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "devices",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceGroupCreate_createDeviceGroup_Mutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviceGroupCreate_createDeviceGroup_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e09d88ea5ddcf566a956b194bb4831e0",
    "id": null,
    "metadata": {},
    "name": "DeviceGroupCreate_createDeviceGroup_Mutation",
    "operationKind": "mutation",
    "text": "mutation DeviceGroupCreate_createDeviceGroup_Mutation(\n  $input: CreateDeviceGroupInput!\n) {\n  createDeviceGroup(input: $input) {\n    deviceGroup {\n      id\n      name\n      handle\n      selector\n      devices {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d13a9c6b7f95516ef4341378d02eaece";

export default node;
