/**
 * @generated SignedSource<<ef893eec2d223ddfb6ce28f9398bb4e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteBaseImageCollectionInput = {
  baseImageCollectionId: string;
};
export type BaseImageCollection_deleteBaseImageCollection_Mutation$variables = {
  input: DeleteBaseImageCollectionInput;
};
export type BaseImageCollection_deleteBaseImageCollection_Mutation$data = {
  readonly deleteBaseImageCollection: {
    readonly baseImageCollection: {
      readonly id: string;
    };
  } | null;
};
export type BaseImageCollection_deleteBaseImageCollection_Mutation = {
  response: BaseImageCollection_deleteBaseImageCollection_Mutation$data;
  variables: BaseImageCollection_deleteBaseImageCollection_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteBaseImageCollectionPayload",
    "kind": "LinkedField",
    "name": "deleteBaseImageCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BaseImageCollection",
        "kind": "LinkedField",
        "name": "baseImageCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BaseImageCollection_deleteBaseImageCollection_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BaseImageCollection_deleteBaseImageCollection_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fcd4310649d66750568745179dc3da67",
    "id": null,
    "metadata": {},
    "name": "BaseImageCollection_deleteBaseImageCollection_Mutation",
    "operationKind": "mutation",
    "text": "mutation BaseImageCollection_deleteBaseImageCollection_Mutation(\n  $input: DeleteBaseImageCollectionInput!\n) {\n  deleteBaseImageCollection(input: $input) {\n    baseImageCollection {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e0d7d4f7aff736e16fe56b073a12e1a6";

export default node;
