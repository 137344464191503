/**
 * @generated SignedSource<<029b2c7ce11eb67fc95df55f8c2f25b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateCampaignStatsChart_UpdateCampaignStatsChartFragment$data = {
  readonly stats: {
    readonly failedTargetCount: number;
    readonly idleTargetCount: number;
    readonly inProgressTargetCount: number;
    readonly successfulTargetCount: number;
  };
  readonly " $fragmentType": "UpdateCampaignStatsChart_UpdateCampaignStatsChartFragment";
};
export type UpdateCampaignStatsChart_UpdateCampaignStatsChartFragment$key = {
  readonly " $data"?: UpdateCampaignStatsChart_UpdateCampaignStatsChartFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateCampaignStatsChart_UpdateCampaignStatsChartFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateCampaignStatsChart_UpdateCampaignStatsChartFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UpdateCampaignStats",
      "kind": "LinkedField",
      "name": "stats",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "idleTargetCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inProgressTargetCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "failedTargetCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "successfulTargetCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UpdateCampaign",
  "abstractKey": null
};

(node as any).hash = "62bfd4c607f33906ef3a53e80f6f686b";

export default node;
