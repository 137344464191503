/**
 * @generated SignedSource<<70f81e0782ef66d5b1d65a31dc886fd1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceGroups_getDeviceGroups_Query$variables = {};
export type DeviceGroups_getDeviceGroups_Query$data = {
  readonly deviceGroups: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"DeviceGroupsTable_DeviceGroupFragment">;
  }>;
};
export type DeviceGroups_getDeviceGroups_Query = {
  response: DeviceGroups_getDeviceGroups_Query$data;
  variables: DeviceGroups_getDeviceGroups_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceGroups_getDeviceGroups_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceGroup",
        "kind": "LinkedField",
        "name": "deviceGroups",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DeviceGroupsTable_DeviceGroupFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeviceGroups_getDeviceGroups_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceGroup",
        "kind": "LinkedField",
        "name": "deviceGroups",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "selector",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "411a65d363fe705868691d8efd510e99",
    "id": null,
    "metadata": {},
    "name": "DeviceGroups_getDeviceGroups_Query",
    "operationKind": "query",
    "text": "query DeviceGroups_getDeviceGroups_Query {\n  deviceGroups {\n    ...DeviceGroupsTable_DeviceGroupFragment\n    id\n  }\n}\n\nfragment DeviceGroupsTable_DeviceGroupFragment on DeviceGroup {\n  id\n  name\n  handle\n  selector\n}\n"
  }
};

(node as any).hash = "dd40e7a9d618945f18a9aab077990189";

export default node;
