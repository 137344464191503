/**
 * @generated SignedSource<<0b1596208e267118fb8519eb4854e144>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceCapability = "BASE_IMAGE" | "BATTERY_STATUS" | "CELLULAR_CONNECTION" | "COMMANDS" | "GEOLOCATION" | "HARDWARE_INFO" | "LED_BEHAVIORS" | "NETWORK_INTERFACE_INFO" | "OPERATING_SYSTEM" | "RUNTIME_INFO" | "SOFTWARE_UPDATES" | "STORAGE" | "SYSTEM_INFO" | "SYSTEM_STATUS" | "TELEMETRY_CONFIG" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type Device_baseImage$data = {
  readonly baseImage: {
    readonly buildId: string | null;
    readonly fingerprint: string | null;
    readonly name: string | null;
    readonly version: string | null;
  } | null;
  readonly capabilities: ReadonlyArray<DeviceCapability>;
  readonly " $fragmentType": "Device_baseImage";
};
export type Device_baseImage$key = {
  readonly " $data"?: Device_baseImage$data;
  readonly " $fragmentSpreads": FragmentRefs<"Device_baseImage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Device_baseImage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capabilities",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseImageInfo",
      "kind": "LinkedField",
      "name": "baseImage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "version",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "buildId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fingerprint",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "a5c6456d4c99ef1225b42897197a5c31";

export default node;
