/**
 * @generated SignedSource<<3e14cf6cd0bd5a495bdb08ed1373e1c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BatteryStatus = "CHARGING" | "DISCHARGING" | "EITHER_IDLE_OR_CHARGING" | "FAILURE" | "IDLE" | "REMOVED" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type BatteryTable_batteryStatus$data = {
  readonly batteryStatus: ReadonlyArray<{
    readonly levelAbsoluteError: number | null;
    readonly levelPercentage: number | null;
    readonly slot: string;
    readonly status: BatteryStatus | null;
  }> | null;
  readonly " $fragmentType": "BatteryTable_batteryStatus";
};
export type BatteryTable_batteryStatus$key = {
  readonly " $data"?: BatteryTable_batteryStatus$data;
  readonly " $fragmentSpreads": FragmentRefs<"BatteryTable_batteryStatus">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BatteryTable_batteryStatus",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BatterySlot",
      "kind": "LinkedField",
      "name": "batteryStatus",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slot",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "levelPercentage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "levelAbsoluteError",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "94c5e0076b678ff4f0c66c5033f32b6d";

export default node;
