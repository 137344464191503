/**
 * @generated SignedSource<<3efbb8600fd5f9328dd9c64bd34f5f12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UpdateCampaignOutcome = "FAILURE" | "SUCCESS";
export type UpdateCampaignStatus = "FINISHED" | "IDLE" | "IN_PROGRESS";
import { FragmentRefs } from "relay-runtime";
import baseImageNameResolver from "../BaseImageNameResolver.ts";
export type UpdateCampaignsTable_UpdateCampaignFragment$data = ReadonlyArray<{
  readonly baseImage: {
    readonly baseImageCollection: {
      readonly name: string;
    };
    readonly name: ReturnType<typeof baseImageNameResolver> | null;
  };
  readonly id: string;
  readonly name: string;
  readonly outcome: UpdateCampaignOutcome | null;
  readonly status: UpdateCampaignStatus;
  readonly updateChannel: {
    readonly name: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"UpdateCampaignOutcome_UpdateCampaignOutcomeFragment" | "UpdateCampaignStatus_UpdateCampaignStatusFragment">;
  readonly " $fragmentType": "UpdateCampaignsTable_UpdateCampaignFragment";
}>;
export type UpdateCampaignsTable_UpdateCampaignFragment$key = ReadonlyArray<{
  readonly " $data"?: UpdateCampaignsTable_UpdateCampaignFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateCampaignsTable_UpdateCampaignFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UpdateCampaignsTable_UpdateCampaignFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateCampaignStatus_UpdateCampaignStatusFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outcome",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateCampaignOutcome_UpdateCampaignOutcomeFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseImage",
      "kind": "LinkedField",
      "name": "baseImage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BaseImageNameResolver"
          },
          "kind": "RelayResolver",
          "name": "name",
          "resolverModule": require('./../BaseImageNameResolver'),
          "path": "baseImage.name"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BaseImageCollection",
          "kind": "LinkedField",
          "name": "baseImageCollection",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UpdateChannel",
      "kind": "LinkedField",
      "name": "updateChannel",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "UpdateCampaign",
  "abstractKey": null
};
})();

(node as any).hash = "2dfc7366c50622bf77e4ee55fc9be081";

export default node;
