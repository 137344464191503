/**
 * @generated SignedSource<<74959bcd0e3012aea51d1d211b5137ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateUpdateCampaign_OptionsFragment$data = {
  readonly baseImageCollections: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly updateChannels: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentType": "CreateUpdateCampaign_OptionsFragment";
};
export type CreateUpdateCampaign_OptionsFragment$key = {
  readonly " $data"?: CreateUpdateCampaign_OptionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateUpdateCampaign_OptionsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateUpdateCampaign_OptionsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseImageCollection",
      "kind": "LinkedField",
      "name": "baseImageCollections",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UpdateChannel",
      "kind": "LinkedField",
      "name": "updateChannels",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "RootQueryType",
  "abstractKey": null
};
})();

(node as any).hash = "f692025604bde265115128981331691f";

export default node;
