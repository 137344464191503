/**
 * @generated SignedSource<<0d50b62a1827799dd33c63481e472b39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import baseImageNameResolver from "../BaseImageNameResolver.ts";
export type BaseImageSelect_getBaseImages_Query$variables = {
  baseImageCollectionId: string;
};
export type BaseImageSelect_getBaseImages_Query$data = {
  readonly baseImageCollection: {
    readonly baseImages: ReadonlyArray<{
      readonly id: string;
      readonly name: ReturnType<typeof baseImageNameResolver> | null;
    }>;
  } | null;
};
export type BaseImageSelect_getBaseImages_Query = {
  response: BaseImageSelect_getBaseImages_Query$data;
  variables: BaseImageSelect_getBaseImages_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "baseImageCollectionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "baseImageCollectionId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BaseImageSelect_getBaseImages_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BaseImageCollection",
        "kind": "LinkedField",
        "name": "baseImageCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BaseImage",
            "kind": "LinkedField",
            "name": "baseImages",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "fragment": {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "BaseImageNameResolver"
                },
                "kind": "RelayResolver",
                "name": "name",
                "resolverModule": require('./../BaseImageNameResolver'),
                "path": "baseImageCollection.baseImages.name"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BaseImageSelect_getBaseImages_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BaseImageCollection",
        "kind": "LinkedField",
        "name": "baseImageCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BaseImage",
            "kind": "LinkedField",
            "name": "baseImages",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "releaseDisplayName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5b83cac7552bec46ed9f2977af0fdbdf",
    "id": null,
    "metadata": {},
    "name": "BaseImageSelect_getBaseImages_Query",
    "operationKind": "query",
    "text": "query BaseImageSelect_getBaseImages_Query(\n  $baseImageCollectionId: ID!\n) {\n  baseImageCollection(id: $baseImageCollectionId) {\n    baseImages {\n      id\n      ...BaseImageNameResolver\n    }\n    id\n  }\n}\n\nfragment BaseImageNameResolver on BaseImage {\n  version\n  releaseDisplayName\n}\n"
  }
};
})();

(node as any).hash = "5a0c9d0ab661d7d0a3652aa9452ba566";

export default node;
