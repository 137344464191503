/**
 * @generated SignedSource<<87c330b197961628f405fc24eec64bea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UpdateCampaignStatus = "FINISHED" | "IDLE" | "IN_PROGRESS";
import { FragmentRefs } from "relay-runtime";
export type UpdateCampaign_RefreshFragment$data = {
  readonly id: string;
  readonly status: UpdateCampaignStatus;
  readonly " $fragmentType": "UpdateCampaign_RefreshFragment";
};
export type UpdateCampaign_RefreshFragment$key = {
  readonly " $data"?: UpdateCampaign_RefreshFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateCampaign_RefreshFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateCampaign_RefreshFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "UpdateCampaign",
  "abstractKey": null
};

(node as any).hash = "d8fc2dcc8203bc39e9bebdf708cb9609";

export default node;
