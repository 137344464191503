/**
 * @generated SignedSource<<c472fff943413789285e02fe82a862d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateBaseImageInput = {
  baseImageId: string;
  description?: LocalizedTextInput | null;
  releaseDisplayName?: LocalizedTextInput | null;
  startingVersionRequirement?: string | null;
};
export type LocalizedTextInput = {
  locale: string;
  text: string;
};
export type BaseImage_updateBaseImage_Mutation$variables = {
  input: UpdateBaseImageInput;
};
export type BaseImage_updateBaseImage_Mutation$data = {
  readonly updateBaseImage: {
    readonly baseImage: {
      readonly description: string | null;
      readonly id: string;
      readonly releaseDisplayName: string | null;
      readonly startingVersionRequirement: string | null;
    };
  } | null;
};
export type BaseImage_updateBaseImage_Mutation = {
  response: BaseImage_updateBaseImage_Mutation$data;
  variables: BaseImage_updateBaseImage_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateBaseImagePayload",
    "kind": "LinkedField",
    "name": "updateBaseImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BaseImage",
        "kind": "LinkedField",
        "name": "baseImage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startingVersionRequirement",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "releaseDisplayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BaseImage_updateBaseImage_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BaseImage_updateBaseImage_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6515f3230df4e7dcb7d0c4183902e907",
    "id": null,
    "metadata": {},
    "name": "BaseImage_updateBaseImage_Mutation",
    "operationKind": "mutation",
    "text": "mutation BaseImage_updateBaseImage_Mutation(\n  $input: UpdateBaseImageInput!\n) {\n  updateBaseImage(input: $input) {\n    baseImage {\n      id\n      startingVersionRequirement\n      description\n      releaseDisplayName\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "39cf337602f5c249214f683dffb02a24";

export default node;
