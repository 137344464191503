/**
 * @generated SignedSource<<01a4c4253c9cf30e222b09ce1d68b5af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Devices_getDevices_Query$variables = {};
export type Devices_getDevices_Query$data = {
  readonly devices: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"DevicesTable_DeviceFragment">;
  }>;
};
export type Devices_getDevices_Query = {
  response: Devices_getDevices_Query$data;
  variables: Devices_getDevices_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Devices_getDevices_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "devices",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DevicesTable_DeviceFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Devices_getDevices_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "devices",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastConnection",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastDisconnection",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "online",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SystemModel",
            "kind": "LinkedField",
            "name": "systemModel",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "HardwareType",
                "kind": "LinkedField",
                "name": "hardwareType",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f622f537e7c523bd2fec80e074fc68df",
    "id": null,
    "metadata": {},
    "name": "Devices_getDevices_Query",
    "operationKind": "query",
    "text": "query Devices_getDevices_Query {\n  devices {\n    ...DevicesTable_DeviceFragment\n    id\n  }\n}\n\nfragment DevicesTable_DeviceFragment on Device {\n  id\n  deviceId\n  lastConnection\n  lastDisconnection\n  name\n  online\n  systemModel {\n    name\n    hardwareType {\n      name\n      id\n    }\n    id\n  }\n  tags\n}\n"
  }
};
})();

(node as any).hash = "96b79e825afdeb064aa74f69e549e073";

export default node;
