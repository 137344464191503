/**
 * @generated SignedSource<<a2fc1eb03a89cb28905c168659a53258>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BaseImageNameResolver$data = {
  readonly releaseDisplayName: string | null;
  readonly version: string;
  readonly " $fragmentType": "BaseImageNameResolver";
};
export type BaseImageNameResolver$key = {
  readonly " $data"?: BaseImageNameResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"BaseImageNameResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BaseImageNameResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releaseDisplayName",
      "storageKey": null
    }
  ],
  "type": "BaseImage",
  "abstractKey": null
};

(node as any).hash = "a55d5bd4b7c437cfb491fef84c8c6a35";

export default node;
