/**
 * @generated SignedSource<<ad52d5a5afb36c8618a06472ba7b3b13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateCampaigns_getUpdateCampaigns_Query$variables = {};
export type UpdateCampaigns_getUpdateCampaigns_Query$data = {
  readonly updateCampaigns: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UpdateCampaignsTable_UpdateCampaignFragment">;
  }>;
};
export type UpdateCampaigns_getUpdateCampaigns_Query = {
  response: UpdateCampaigns_getUpdateCampaigns_Query$data;
  variables: UpdateCampaigns_getUpdateCampaigns_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCampaigns_getUpdateCampaigns_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateCampaign",
        "kind": "LinkedField",
        "name": "updateCampaigns",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UpdateCampaignsTable_UpdateCampaignFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UpdateCampaigns_getUpdateCampaigns_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateCampaign",
        "kind": "LinkedField",
        "name": "updateCampaigns",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "outcome",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BaseImage",
            "kind": "LinkedField",
            "name": "baseImage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "releaseDisplayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BaseImageCollection",
                "kind": "LinkedField",
                "name": "baseImageCollection",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UpdateChannel",
            "kind": "LinkedField",
            "name": "updateChannel",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "72472e5c1b73d44a7f27f968b72fbf56",
    "id": null,
    "metadata": {},
    "name": "UpdateCampaigns_getUpdateCampaigns_Query",
    "operationKind": "query",
    "text": "query UpdateCampaigns_getUpdateCampaigns_Query {\n  updateCampaigns {\n    ...UpdateCampaignsTable_UpdateCampaignFragment\n    id\n  }\n}\n\nfragment BaseImageNameResolver on BaseImage {\n  version\n  releaseDisplayName\n}\n\nfragment UpdateCampaignOutcome_UpdateCampaignOutcomeFragment on UpdateCampaign {\n  outcome\n}\n\nfragment UpdateCampaignStatus_UpdateCampaignStatusFragment on UpdateCampaign {\n  status\n}\n\nfragment UpdateCampaignsTable_UpdateCampaignFragment on UpdateCampaign {\n  id\n  name\n  status\n  ...UpdateCampaignStatus_UpdateCampaignStatusFragment\n  outcome\n  ...UpdateCampaignOutcome_UpdateCampaignOutcomeFragment\n  baseImage {\n    ...BaseImageNameResolver\n    baseImageCollection {\n      name\n      id\n    }\n    id\n  }\n  updateChannel {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fa8cbb11fdaf54c0567ad18c95d4f974";

export default node;
