/**
 * @generated SignedSource<<8543e75247ce381f6f1e8da75c43b868>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteUpdateChannelInput = {
  updateChannelId: string;
};
export type UpdateChannel_deleteUpdateChannel_Mutation$variables = {
  input: DeleteUpdateChannelInput;
};
export type UpdateChannel_deleteUpdateChannel_Mutation$data = {
  readonly deleteUpdateChannel: {
    readonly updateChannel: {
      readonly id: string;
      readonly targetGroups: ReadonlyArray<{
        readonly id: string;
      }>;
    };
  } | null;
};
export type UpdateChannel_deleteUpdateChannel_Mutation = {
  response: UpdateChannel_deleteUpdateChannel_Mutation$data;
  variables: UpdateChannel_deleteUpdateChannel_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteUpdateChannelPayload",
    "kind": "LinkedField",
    "name": "deleteUpdateChannel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateChannel",
        "kind": "LinkedField",
        "name": "updateChannel",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceGroup",
            "kind": "LinkedField",
            "name": "targetGroups",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateChannel_deleteUpdateChannel_Mutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateChannel_deleteUpdateChannel_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7931c937d989c350f05bfa3554525443",
    "id": null,
    "metadata": {},
    "name": "UpdateChannel_deleteUpdateChannel_Mutation",
    "operationKind": "mutation",
    "text": "mutation UpdateChannel_deleteUpdateChannel_Mutation(\n  $input: DeleteUpdateChannelInput!\n) {\n  deleteUpdateChannel(input: $input) {\n    updateChannel {\n      id\n      targetGroups {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b005e5473e1c59176c9d17ebc582598";

export default node;
