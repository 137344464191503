/**
 * @generated SignedSource<<3fd2a59cecc0dd79a0e344874bbb7154>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceGroupsTable_DeviceGroupFragment$data = ReadonlyArray<{
  readonly handle: string;
  readonly id: string;
  readonly name: string;
  readonly selector: string;
  readonly " $fragmentType": "DeviceGroupsTable_DeviceGroupFragment";
}>;
export type DeviceGroupsTable_DeviceGroupFragment$key = ReadonlyArray<{
  readonly " $data"?: DeviceGroupsTable_DeviceGroupFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceGroupsTable_DeviceGroupFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "DeviceGroupsTable_DeviceGroupFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selector",
      "storageKey": null
    }
  ],
  "type": "DeviceGroup",
  "abstractKey": null
};

(node as any).hash = "b8fa96c220cba76d005bce4fc4cea988";

export default node;
