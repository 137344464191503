/**
 * @generated SignedSource<<3ad91a376788eb6c7079165e21b21e7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateCampaignCreate_getOptions_Query$variables = {};
export type UpdateCampaignCreate_getOptions_Query$data = {
  readonly baseImageCollections: ReadonlyArray<{
    readonly __typename: "BaseImageCollection";
  }>;
  readonly updateChannels: ReadonlyArray<{
    readonly __typename: "UpdateChannel";
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"CreateUpdateCampaign_OptionsFragment">;
};
export type UpdateCampaignCreate_getOptions_Query = {
  response: UpdateCampaignCreate_getOptions_Query$data;
  variables: UpdateCampaignCreate_getOptions_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCampaignCreate_getOptions_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BaseImageCollection",
        "kind": "LinkedField",
        "name": "baseImageCollections",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateChannel",
        "kind": "LinkedField",
        "name": "updateChannels",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CreateUpdateCampaign_OptionsFragment"
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UpdateCampaignCreate_getOptions_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BaseImageCollection",
        "kind": "LinkedField",
        "name": "baseImageCollections",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UpdateChannel",
        "kind": "LinkedField",
        "name": "updateChannels",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "44e2358d9bb813da983622b12a2de66b",
    "id": null,
    "metadata": {},
    "name": "UpdateCampaignCreate_getOptions_Query",
    "operationKind": "query",
    "text": "query UpdateCampaignCreate_getOptions_Query {\n  baseImageCollections {\n    __typename\n    id\n  }\n  updateChannels {\n    __typename\n    id\n  }\n  ...CreateUpdateCampaign_OptionsFragment\n}\n\nfragment CreateUpdateCampaign_OptionsFragment on RootQueryType {\n  baseImageCollections {\n    id\n    name\n  }\n  updateChannels {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "0f2abcde84d436b2edd65748796dcc2e";

export default node;
