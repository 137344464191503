/**
 * @generated SignedSource<<71a74f33d292e0d5829d8440b07e6dfa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceCapability = "BASE_IMAGE" | "BATTERY_STATUS" | "CELLULAR_CONNECTION" | "COMMANDS" | "GEOLOCATION" | "HARDWARE_INFO" | "LED_BEHAVIORS" | "NETWORK_INTERFACE_INFO" | "OPERATING_SYSTEM" | "RUNTIME_INFO" | "SOFTWARE_UPDATES" | "STORAGE" | "SYSTEM_INFO" | "SYSTEM_STATUS" | "TELEMETRY_CONFIG" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type Device_batteryStatus$data = {
  readonly capabilities: ReadonlyArray<DeviceCapability>;
  readonly " $fragmentSpreads": FragmentRefs<"BatteryTable_batteryStatus">;
  readonly " $fragmentType": "Device_batteryStatus";
};
export type Device_batteryStatus$key = {
  readonly " $data"?: Device_batteryStatus$data;
  readonly " $fragmentSpreads": FragmentRefs<"Device_batteryStatus">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Device_batteryStatus",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capabilities",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BatteryTable_batteryStatus"
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "77f28451dac2f1d6e5de1110b09cece3";

export default node;
