/**
 * @generated SignedSource<<262f086351b61c266bf39b1b5770258d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BaseImageCollection_getBaseImageCollection_Query$variables = {
  id: string;
};
export type BaseImageCollection_getBaseImageCollection_Query$data = {
  readonly baseImageCollection: {
    readonly handle: string;
    readonly id: string;
    readonly name: string;
    readonly systemModel: {
      readonly name: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"BaseImagesTable_BaseImagesFragment">;
  } | null;
};
export type BaseImageCollection_getBaseImageCollection_Query = {
  response: BaseImageCollection_getBaseImageCollection_Query$data;
  variables: BaseImageCollection_getBaseImageCollection_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BaseImageCollection_getBaseImageCollection_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BaseImageCollection",
        "kind": "LinkedField",
        "name": "baseImageCollection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SystemModel",
            "kind": "LinkedField",
            "name": "systemModel",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BaseImagesTable_BaseImagesFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BaseImageCollection_getBaseImageCollection_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BaseImageCollection",
        "kind": "LinkedField",
        "name": "baseImageCollection",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SystemModel",
            "kind": "LinkedField",
            "name": "systemModel",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BaseImage",
            "kind": "LinkedField",
            "name": "baseImages",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startingVersionRequirement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "releaseDisplayName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "71e620a26aa623f75f1e74acbec616ae",
    "id": null,
    "metadata": {},
    "name": "BaseImageCollection_getBaseImageCollection_Query",
    "operationKind": "query",
    "text": "query BaseImageCollection_getBaseImageCollection_Query(\n  $id: ID!\n) {\n  baseImageCollection(id: $id) {\n    id\n    name\n    handle\n    systemModel {\n      name\n      id\n    }\n    ...BaseImagesTable_BaseImagesFragment\n  }\n}\n\nfragment BaseImagesTable_BaseImagesFragment on BaseImageCollection {\n  id\n  baseImages {\n    id\n    version\n    startingVersionRequirement\n    releaseDisplayName\n  }\n}\n"
  }
};
})();

(node as any).hash = "d64c76ec09971dbae2eb17b243e2a06f";

export default node;
