/**
 * @generated SignedSource<<975163cd684416981cfa83b3b35a9235>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceCapability = "BASE_IMAGE" | "BATTERY_STATUS" | "CELLULAR_CONNECTION" | "COMMANDS" | "GEOLOCATION" | "HARDWARE_INFO" | "LED_BEHAVIORS" | "NETWORK_INTERFACE_INFO" | "OPERATING_SYSTEM" | "RUNTIME_INFO" | "SOFTWARE_UPDATES" | "STORAGE" | "SYSTEM_INFO" | "SYSTEM_STATUS" | "TELEMETRY_CONFIG" | "WIFI";
export type OtaOperationStatus = "ACKNOWLEDGED" | "DEPLOYED" | "DEPLOYING" | "DOWNLOADING" | "ERROR" | "FAILURE" | "PENDING" | "REBOOTING" | "SUCCESS";
import { FragmentRefs } from "relay-runtime";
export type Device_otaOperations$data = {
  readonly capabilities: ReadonlyArray<DeviceCapability>;
  readonly id: string;
  readonly otaOperations: ReadonlyArray<{
    readonly baseImageUrl: string;
    readonly createdAt: string;
    readonly id: string;
    readonly status: OtaOperationStatus;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"OperationTable_otaOperations">;
  readonly " $fragmentType": "Device_otaOperations";
};
export type Device_otaOperations$key = {
  readonly " $data"?: Device_otaOperations$data;
  readonly " $fragmentSpreads": FragmentRefs<"Device_otaOperations">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Device_otaOperations",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capabilities",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OtaOperation",
      "kind": "LinkedField",
      "name": "otaOperations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "baseImageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OperationTable_otaOperations"
    }
  ],
  "type": "Device",
  "abstractKey": null
};
})();

(node as any).hash = "04710a712109941f7124d1c058c13c61";

export default node;
