/**
 * @generated SignedSource<<d4e67fafc6d2cdfaad28698a13a761a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeviceAttributeNamespace = "CUSTOM";
export type VariantType = "BINARYBLOB" | "BOOLEAN" | "DATETIME" | "DOUBLE" | "INTEGER" | "LONGINTEGER" | "STRING";
export type UpdateDeviceInput = {
  customAttributes?: ReadonlyArray<DeviceAttributeInput> | null;
  deviceId: string;
  name?: string | null;
  tags?: ReadonlyArray<string> | null;
};
export type DeviceAttributeInput = {
  key: string;
  namespace: DeviceAttributeNamespace;
  type: VariantType;
  value: any;
};
export type Device_updateDevice_Mutation$variables = {
  input: UpdateDeviceInput;
};
export type Device_updateDevice_Mutation$data = {
  readonly updateDevice: {
    readonly device: {
      readonly deviceGroups: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
      readonly id: string;
      readonly name: string;
      readonly tags: ReadonlyArray<string>;
    };
  } | null;
};
export type Device_updateDevice_Mutation = {
  response: Device_updateDevice_Mutation$data;
  variables: Device_updateDevice_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateDevicePayload",
    "kind": "LinkedField",
    "name": "updateDevice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceGroup",
            "kind": "LinkedField",
            "name": "deviceGroups",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Device_updateDevice_Mutation",
    "selections": (v3/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Device_updateDevice_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "a5e125eb2d87f6fe3569091a526fe556",
    "id": null,
    "metadata": {},
    "name": "Device_updateDevice_Mutation",
    "operationKind": "mutation",
    "text": "mutation Device_updateDevice_Mutation(\n  $input: UpdateDeviceInput!\n) {\n  updateDevice(input: $input) {\n    device {\n      id\n      name\n      tags\n      deviceGroups {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0a9d3ed1c485b87c53e02ad458271fdc";

export default node;
