/**
 * @generated SignedSource<<4e7b638ea98a6d0c0c97d073b48d7c52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BaseImageCollectionsTable_BaseImageCollectionFragment$data = ReadonlyArray<{
  readonly handle: string;
  readonly id: string;
  readonly name: string;
  readonly systemModel: {
    readonly name: string;
  } | null;
  readonly " $fragmentType": "BaseImageCollectionsTable_BaseImageCollectionFragment";
}>;
export type BaseImageCollectionsTable_BaseImageCollectionFragment$key = ReadonlyArray<{
  readonly " $data"?: BaseImageCollectionsTable_BaseImageCollectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BaseImageCollectionsTable_BaseImageCollectionFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BaseImageCollectionsTable_BaseImageCollectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SystemModel",
      "kind": "LinkedField",
      "name": "systemModel",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "BaseImageCollection",
  "abstractKey": null
};
})();

(node as any).hash = "30545ca6bf7eca76cf70d6950d1c5bdd";

export default node;
