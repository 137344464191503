/**
 * @generated SignedSource<<ee83e7710c0126d7ef70be4f8e7702fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UpdateCampaignOutcome = "FAILURE" | "SUCCESS";
import { FragmentRefs } from "relay-runtime";
export type UpdateCampaignOutcome_UpdateCampaignOutcomeFragment$data = {
  readonly outcome: UpdateCampaignOutcome | null;
  readonly " $fragmentType": "UpdateCampaignOutcome_UpdateCampaignOutcomeFragment";
};
export type UpdateCampaignOutcome_UpdateCampaignOutcomeFragment$key = {
  readonly " $data"?: UpdateCampaignOutcome_UpdateCampaignOutcomeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateCampaignOutcome_UpdateCampaignOutcomeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateCampaignOutcome_UpdateCampaignOutcomeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outcome",
      "storageKey": null
    }
  ],
  "type": "UpdateCampaign",
  "abstractKey": null
};

(node as any).hash = "ab0a0af83ec8a95e351c96ba28a00f2f";

export default node;
