/**
 * @generated SignedSource<<5f3887ce26663121cde187f73c9aad43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DevicesTable_DeviceFragment$data = ReadonlyArray<{
  readonly deviceId: string;
  readonly id: string;
  readonly lastConnection: string | null;
  readonly lastDisconnection: string | null;
  readonly name: string;
  readonly online: boolean;
  readonly systemModel: {
    readonly hardwareType: {
      readonly name: string;
    };
    readonly name: string;
  } | null;
  readonly tags: ReadonlyArray<string>;
  readonly " $fragmentType": "DevicesTable_DeviceFragment";
}>;
export type DevicesTable_DeviceFragment$key = ReadonlyArray<{
  readonly " $data"?: DevicesTable_DeviceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DevicesTable_DeviceFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "DevicesTable_DeviceFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastConnection",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastDisconnection",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "online",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SystemModel",
      "kind": "LinkedField",
      "name": "systemModel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "HardwareType",
          "kind": "LinkedField",
          "name": "hardwareType",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};
})();

(node as any).hash = "441231b1b8d1377f93fe0d6aa279c639";

export default node;
