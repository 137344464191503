/**
 * @generated SignedSource<<2f6f8b3ea0e5138d2605bb65e7718b58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateSystemModelInput = {
  description?: LocalizedTextInput | null;
  handle: string;
  hardwareTypeId: string;
  name: string;
  partNumbers: ReadonlyArray<string>;
  pictureFile?: File | null;
  pictureUrl?: string | null;
};
export type LocalizedTextInput = {
  locale: string;
  text: string;
};
export type SystemModelCreate_createSystemModel_Mutation$variables = {
  input: CreateSystemModelInput;
};
export type SystemModelCreate_createSystemModel_Mutation$data = {
  readonly createSystemModel: {
    readonly systemModel: {
      readonly description: string | null;
      readonly handle: string;
      readonly hardwareType: {
        readonly id: string;
        readonly name: string;
      };
      readonly id: string;
      readonly name: string;
      readonly partNumbers: ReadonlyArray<string>;
      readonly pictureUrl: string | null;
    };
  } | null;
};
export type SystemModelCreate_createSystemModel_Mutation = {
  response: SystemModelCreate_createSystemModel_Mutation$data;
  variables: SystemModelCreate_createSystemModel_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateSystemModelPayload",
    "kind": "LinkedField",
    "name": "createSystemModel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SystemModel",
        "kind": "LinkedField",
        "name": "systemModel",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HardwareType",
            "kind": "LinkedField",
            "name": "hardwareType",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "partNumbers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pictureUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SystemModelCreate_createSystemModel_Mutation",
    "selections": (v3/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SystemModelCreate_createSystemModel_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "4bd2f00949b05e47708774c969c8f7b2",
    "id": null,
    "metadata": {},
    "name": "SystemModelCreate_createSystemModel_Mutation",
    "operationKind": "mutation",
    "text": "mutation SystemModelCreate_createSystemModel_Mutation(\n  $input: CreateSystemModelInput!\n) {\n  createSystemModel(input: $input) {\n    systemModel {\n      id\n      name\n      handle\n      description\n      hardwareType {\n        id\n        name\n      }\n      partNumbers\n      pictureUrl\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "91ddd745973059772b68a16d2d9de3c9";

export default node;
