/**
 * @generated SignedSource<<f8839898c9b347b8728334f4beea0e35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UpdateTargetStatus = "FAILED" | "IDLE" | "IN_PROGRESS" | "SUCCESSFUL";
import { FragmentRefs } from "relay-runtime";
export type UpdateTargetStatus_UpdateTargetStatusFragment$data = {
  readonly status: UpdateTargetStatus;
  readonly " $fragmentType": "UpdateTargetStatus_UpdateTargetStatusFragment";
};
export type UpdateTargetStatus_UpdateTargetStatusFragment$key = {
  readonly " $data"?: UpdateTargetStatus_UpdateTargetStatusFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateTargetStatus_UpdateTargetStatusFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateTargetStatus_UpdateTargetStatusFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "UpdateTarget",
  "abstractKey": null
};

(node as any).hash = "7fa646c1485b67a7dbab609b22dcfbc8";

export default node;
