/**
 * @generated SignedSource<<2489e45f456ad1167392d31c98b7e883>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceCapability = "BASE_IMAGE" | "BATTERY_STATUS" | "CELLULAR_CONNECTION" | "COMMANDS" | "GEOLOCATION" | "HARDWARE_INFO" | "LED_BEHAVIORS" | "NETWORK_INTERFACE_INFO" | "OPERATING_SYSTEM" | "RUNTIME_INFO" | "SOFTWARE_UPDATES" | "STORAGE" | "SYSTEM_INFO" | "SYSTEM_STATUS" | "TELEMETRY_CONFIG" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type Device_systemStatus$data = {
  readonly capabilities: ReadonlyArray<DeviceCapability>;
  readonly systemStatus: {
    readonly memoryFreeBytes: number | null;
    readonly taskCount: number | null;
    readonly timestamp: string;
    readonly uptimeMilliseconds: number | null;
  } | null;
  readonly " $fragmentType": "Device_systemStatus";
};
export type Device_systemStatus$key = {
  readonly " $data"?: Device_systemStatus$data;
  readonly " $fragmentSpreads": FragmentRefs<"Device_systemStatus">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Device_systemStatus",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capabilities",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SystemStatus",
      "kind": "LinkedField",
      "name": "systemStatus",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "memoryFreeBytes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taskCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uptimeMilliseconds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timestamp",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "c5843e051e806f276b89a90588033a3a";

export default node;
