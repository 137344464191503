/**
 * @generated SignedSource<<d65374429c30c1a670e2b6ea89408b8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceCapability = "BASE_IMAGE" | "BATTERY_STATUS" | "CELLULAR_CONNECTION" | "COMMANDS" | "GEOLOCATION" | "HARDWARE_INFO" | "LED_BEHAVIORS" | "NETWORK_INTERFACE_INFO" | "OPERATING_SYSTEM" | "RUNTIME_INFO" | "SOFTWARE_UPDATES" | "STORAGE" | "SYSTEM_INFO" | "SYSTEM_STATUS" | "TELEMETRY_CONFIG" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type Device_osInfo$data = {
  readonly capabilities: ReadonlyArray<DeviceCapability>;
  readonly osInfo: {
    readonly name: string | null;
    readonly version: string | null;
  } | null;
  readonly " $fragmentType": "Device_osInfo";
};
export type Device_osInfo$key = {
  readonly " $data"?: Device_osInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"Device_osInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Device_osInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capabilities",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OsInfo",
      "kind": "LinkedField",
      "name": "osInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "version",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "3f1b7da06c4cf3f705461a702fa8786d";

export default node;
