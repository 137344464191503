/**
 * @generated SignedSource<<026eba8d074b780659a5718815ee2701>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Device_getExistingDeviceTags_Query$variables = {};
export type Device_getExistingDeviceTags_Query$data = {
  readonly existingDeviceTags: ReadonlyArray<string>;
};
export type Device_getExistingDeviceTags_Query = {
  response: Device_getExistingDeviceTags_Query$data;
  variables: Device_getExistingDeviceTags_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "existingDeviceTags",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Device_getExistingDeviceTags_Query",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Device_getExistingDeviceTags_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "5c88f8ae9e63a555f2006ce75a20f8c9",
    "id": null,
    "metadata": {},
    "name": "Device_getExistingDeviceTags_Query",
    "operationKind": "query",
    "text": "query Device_getExistingDeviceTags_Query {\n  existingDeviceTags\n}\n"
  }
};
})();

(node as any).hash = "34f635d1f41e8dfff7f51b52d4bef98a";

export default node;
