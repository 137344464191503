/**
 * @generated SignedSource<<cd02b482367c12f2c083feaf4e1cba19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceCapability = "BASE_IMAGE" | "BATTERY_STATUS" | "CELLULAR_CONNECTION" | "COMMANDS" | "GEOLOCATION" | "HARDWARE_INFO" | "LED_BEHAVIORS" | "NETWORK_INTERFACE_INFO" | "OPERATING_SYSTEM" | "RUNTIME_INFO" | "SOFTWARE_UPDATES" | "STORAGE" | "SYSTEM_INFO" | "SYSTEM_STATUS" | "TELEMETRY_CONFIG" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type Device_hardwareInfo$data = {
  readonly capabilities: ReadonlyArray<DeviceCapability>;
  readonly hardwareInfo: {
    readonly cpuArchitecture: string | null;
    readonly cpuModel: string | null;
    readonly cpuModelName: string | null;
    readonly cpuVendor: string | null;
    readonly memoryTotalBytes: number | null;
  } | null;
  readonly " $fragmentType": "Device_hardwareInfo";
};
export type Device_hardwareInfo$key = {
  readonly " $data"?: Device_hardwareInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"Device_hardwareInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Device_hardwareInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capabilities",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HardwareInfo",
      "kind": "LinkedField",
      "name": "hardwareInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cpuArchitecture",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cpuModel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cpuModelName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cpuVendor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "memoryTotalBytes",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "046487355c6d139f5101fe295313fdbd";

export default node;
