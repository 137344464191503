/**
 * @generated SignedSource<<e3a5e5af46a1a207a9851f0d47fc9cb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BaseImageCollections_getBaseImageCollections_Query$variables = {};
export type BaseImageCollections_getBaseImageCollections_Query$data = {
  readonly baseImageCollections: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"BaseImageCollectionsTable_BaseImageCollectionFragment">;
  }>;
};
export type BaseImageCollections_getBaseImageCollections_Query = {
  response: BaseImageCollections_getBaseImageCollections_Query$data;
  variables: BaseImageCollections_getBaseImageCollections_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BaseImageCollections_getBaseImageCollections_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BaseImageCollection",
        "kind": "LinkedField",
        "name": "baseImageCollections",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BaseImageCollectionsTable_BaseImageCollectionFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BaseImageCollections_getBaseImageCollections_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BaseImageCollection",
        "kind": "LinkedField",
        "name": "baseImageCollections",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SystemModel",
            "kind": "LinkedField",
            "name": "systemModel",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6f4e8c149af93f7c799d2faa76c438b7",
    "id": null,
    "metadata": {},
    "name": "BaseImageCollections_getBaseImageCollections_Query",
    "operationKind": "query",
    "text": "query BaseImageCollections_getBaseImageCollections_Query {\n  baseImageCollections {\n    ...BaseImageCollectionsTable_BaseImageCollectionFragment\n    id\n  }\n}\n\nfragment BaseImageCollectionsTable_BaseImageCollectionFragment on BaseImageCollection {\n  id\n  name\n  handle\n  systemModel {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ccba92286b4a1f0a67910375f3bd5572";

export default node;
