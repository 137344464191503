/**
 * @generated SignedSource<<d6347ca2d51f2ee4165880cbf2150784>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OtaOperationStatus = "ACKNOWLEDGED" | "DEPLOYED" | "DEPLOYING" | "DOWNLOADING" | "ERROR" | "FAILURE" | "PENDING" | "REBOOTING" | "SUCCESS";
import { FragmentRefs } from "relay-runtime";
export type OperationTable_otaOperations$data = {
  readonly otaOperations: ReadonlyArray<{
    readonly baseImageUrl: string;
    readonly createdAt: string;
    readonly status: OtaOperationStatus;
    readonly updatedAt: string;
  }>;
  readonly " $fragmentType": "OperationTable_otaOperations";
};
export type OperationTable_otaOperations$key = {
  readonly " $data"?: OperationTable_otaOperations$data;
  readonly " $fragmentSpreads": FragmentRefs<"OperationTable_otaOperations">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OperationTable_otaOperations",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OtaOperation",
      "kind": "LinkedField",
      "name": "otaOperations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "baseImageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "b9472c4144df80a20fe6b71ef36ec340";

export default node;
