/**
 * @generated SignedSource<<90e92d77ac30e49ddb7e6ac2032da823>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateBaseImageCollectionInput = {
  handle: string;
  name: string;
  systemModelId: string;
};
export type BaseImageCollectionCreate_createBaseImageCollection_Mutation$variables = {
  input: CreateBaseImageCollectionInput;
};
export type BaseImageCollectionCreate_createBaseImageCollection_Mutation$data = {
  readonly createBaseImageCollection: {
    readonly baseImageCollection: {
      readonly handle: string;
      readonly id: string;
      readonly name: string;
      readonly systemModel: {
        readonly name: string;
      } | null;
    };
  } | null;
};
export type BaseImageCollectionCreate_createBaseImageCollection_Mutation = {
  response: BaseImageCollectionCreate_createBaseImageCollection_Mutation$data;
  variables: BaseImageCollectionCreate_createBaseImageCollection_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BaseImageCollectionCreate_createBaseImageCollection_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateBaseImageCollectionPayload",
        "kind": "LinkedField",
        "name": "createBaseImageCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BaseImageCollection",
            "kind": "LinkedField",
            "name": "baseImageCollection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SystemModel",
                "kind": "LinkedField",
                "name": "systemModel",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BaseImageCollectionCreate_createBaseImageCollection_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateBaseImageCollectionPayload",
        "kind": "LinkedField",
        "name": "createBaseImageCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BaseImageCollection",
            "kind": "LinkedField",
            "name": "baseImageCollection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SystemModel",
                "kind": "LinkedField",
                "name": "systemModel",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "08376d5e9f5a4be01c5399fd8ccceb6b",
    "id": null,
    "metadata": {},
    "name": "BaseImageCollectionCreate_createBaseImageCollection_Mutation",
    "operationKind": "mutation",
    "text": "mutation BaseImageCollectionCreate_createBaseImageCollection_Mutation(\n  $input: CreateBaseImageCollectionInput!\n) {\n  createBaseImageCollection(input: $input) {\n    baseImageCollection {\n      id\n      name\n      handle\n      systemModel {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ef6e9e7884482214734632f326877b7d";

export default node;
