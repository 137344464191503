/**
 * @generated SignedSource<<272de8d809ab872d15e74c63fd401e40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceCapability = "BASE_IMAGE" | "BATTERY_STATUS" | "CELLULAR_CONNECTION" | "COMMANDS" | "GEOLOCATION" | "HARDWARE_INFO" | "LED_BEHAVIORS" | "NETWORK_INTERFACE_INFO" | "OPERATING_SYSTEM" | "RUNTIME_INFO" | "SOFTWARE_UPDATES" | "STORAGE" | "SYSTEM_INFO" | "SYSTEM_STATUS" | "TELEMETRY_CONFIG" | "WIFI";
import { FragmentRefs } from "relay-runtime";
export type Device_storageUsage$data = {
  readonly capabilities: ReadonlyArray<DeviceCapability>;
  readonly " $fragmentSpreads": FragmentRefs<"StorageTable_storageUsage">;
  readonly " $fragmentType": "Device_storageUsage";
};
export type Device_storageUsage$key = {
  readonly " $data"?: Device_storageUsage$data;
  readonly " $fragmentSpreads": FragmentRefs<"Device_storageUsage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Device_storageUsage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capabilities",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorageTable_storageUsage"
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "e235389062f924be14bc33028d22bd16";

export default node;
