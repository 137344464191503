/**
 * @generated SignedSource<<78574ae819deec6f58cad78e9a14999e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SystemModel_getSystemModel_Query$variables = {
  id: string;
};
export type SystemModel_getSystemModel_Query$data = {
  readonly systemModel: {
    readonly description: string | null;
    readonly handle: string;
    readonly hardwareType: {
      readonly id: string;
      readonly name: string;
    };
    readonly id: string;
    readonly name: string;
    readonly partNumbers: ReadonlyArray<string>;
    readonly pictureUrl: string | null;
  } | null;
};
export type SystemModel_getSystemModel_Query = {
  response: SystemModel_getSystemModel_Query$data;
  variables: SystemModel_getSystemModel_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "SystemModel",
    "kind": "LinkedField",
    "name": "systemModel",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "handle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HardwareType",
        "kind": "LinkedField",
        "name": "hardwareType",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "partNumbers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pictureUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SystemModel_getSystemModel_Query",
    "selections": (v3/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SystemModel_getSystemModel_Query",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "7e67ad701eee18d70dab7be68df8d2dc",
    "id": null,
    "metadata": {},
    "name": "SystemModel_getSystemModel_Query",
    "operationKind": "query",
    "text": "query SystemModel_getSystemModel_Query(\n  $id: ID!\n) {\n  systemModel(id: $id) {\n    id\n    name\n    handle\n    description\n    hardwareType {\n      id\n      name\n    }\n    partNumbers\n    pictureUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "452c83f24ebf1e512dd4b240e94a0d15";

export default node;
